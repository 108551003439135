export { notify } from "jourbox/notify";

export function init() {
    const class_header = "jourbox-header";
    const class_menu = "jourbox-menu";
    const class_background = "jourbox-menu__background";
    const class_mobile = "jourbox-menu__mobile";
    const class_list = "jourbox-menu__list";
    const class_item = "jourbox-menu__item";
    const class_sublist = "jourbox-menu__sublist";
    const stickyheader = 'jourbox-header--sticky';
    const stickymenu = 'jourbox-menu--sticky';

    init_loader();
    init_mobile_navigation();
    init_sticky_header();
    init_toggle_pannels();

    const isMobile: boolean = (window.innerWidth <= 992);

    window.addEventListener('resize', e => {
        init_sticky_header();
        init_mobile_navigation();
    });

    function init_toggle_pannels() {
        let panels = document.querySelectorAll('.toggle');
        if (panels) {
            for (let panel of panels) {
                let title = panel.querySelector('.panel__title');
                let body = panel.querySelector('.panel__body');

                if (title && body) {
                    title.addEventListener('click', e => {
                        if (panel.classList.contains('open')) {
                            panel.classList.remove('open');
                        } else {
                            panel.classList.add('open');
                        }
                    });
                }
            }
        }
    }

    function init_loader(): void {
        let loader = document.querySelector("#loader") as HTMLElement;

        if (loader !== null) {
            loader.classList.add('fadein');
            loader.classList.add('complete');

            setTimeout(function () {
                loader.classList.remove('complete');
            }, 500);

            setTimeout(function () {
                loader.classList.add('display-none');
                loader.classList.remove('fadein');
            }, 1000);
        }

        on_change_loader(loader);
    }

    function on_change_loader(loader: HTMLElement): void {
        let elements = document.querySelectorAll("[data-jourbox-loader]") as NodeListOf<HTMLElement>;

        for (let element of elements) {
            element.addEventListener('change', e => {

                loader.classList.add('fadein');
                loader.classList.remove('display-none');

                setTimeout(function () {
                    loader.classList.add('complete');
                }, 50);

                if (element.tagName === 'SELECT') {
                    let select = <HTMLSelectElement> element;

                    if (select.form) {
                        select.form.submit();
                    }
                }
            });
        }
    }

    function init_mobile_navigation(): void {
        let navigation = document.querySelector("." + class_mobile) as HTMLElement;
        let subitems = document.querySelectorAll("." + class_item + "--parent a") as NodeListOf<HTMLElement>;
        let sublists = document.querySelectorAll("." + class_item + "--parent > ." + class_sublist) as NodeListOf<HTMLElement>;
        let visible = getComputedStyle(navigation, null).display;

        if (visible == 'none') {
            for (let subitem of subitems) {
                let submenu = <HTMLElement> subitem.nextElementSibling;

                if (submenu && submenu.classList.contains(class_sublist)) {
                    submenu.style.maxHeight = 'initial';
                }
            }
        } else {
            if (navigation) {
                navigation.addEventListener('click', e => {
                    let menu = <HTMLElement> navigation.nextElementSibling;

                    if (menu) {
                        if (menu.classList.contains(class_list + '--show')) {
                            close_mobile_navigation();
                        } else {
                            open_mobile_navigation();
                        }
                    }
                });

                if (subitems && sublists) {
                    for (let sublist of sublists) {
                        if (sublist) {
                            let height = sublist.offsetHeight as number;

                            if (height && sublist.parentElement && sublist.parentElement.nextElementSibling) {
                                sublist.setAttribute('data-height', height.toString());

                                sublist.style.maxHeight = '0px';
                            }
                        }
                    }

                    for (let subitem of subitems) {

                        subitem.addEventListener('click', e => {
                            e.preventDefault();

                            let submenu = <HTMLElement> subitem.nextElementSibling;

                            if (submenu && submenu.classList.contains(class_sublist)) {
                                if (submenu.classList.contains(class_sublist + '--show')) {
                                    submenu.style.maxHeight = '0px';
                                    submenu.classList.remove(class_sublist + '--show');
                                } else {
                                    submenu.style.maxHeight = submenu.getAttribute('data-height') + 'px';
                                    submenu.classList.add(class_sublist + '--show');
                                }
                            }
                        });
                    }
                }
            }
        }
    }

    function open_mobile_navigation() {
        let navigation = document.querySelector("." + class_mobile) as HTMLElement;
        let background = document.querySelector("." + class_background) as HTMLElement;
        let menu = <HTMLElement> navigation.nextElementSibling;

        if (navigation && menu) {
            menu.classList.add(class_list + '--show');
            navigation.classList.add(class_mobile + '--show');
            background.classList.add(class_background + '--show');

            background.addEventListener('click', e => {
                if (e.target !== background) {
                    return false;
                }

                close_mobile_navigation();
            });
        }
    }

    function close_mobile_navigation() {
        let navigation = document.querySelector("." + class_mobile) as HTMLElement;
        let background = document.querySelector("." + class_background) as HTMLElement;
        let menu = <HTMLElement> navigation.nextElementSibling;

        if (navigation && menu && background) {
            menu.classList.remove(class_list + '--show');
            background.classList.remove(class_background + '--show');
            navigation.classList.remove(class_mobile + '--show');
        }
    }

    function init_sticky_header(): void {
        let header = document.querySelector('.' + class_header) as HTMLElement;
        let menu = header.querySelector('.' + class_menu) as HTMLElement;

        if (header && menu) {
            let last_scroll_top = 0;

            window.addEventListener("scroll", e => {
                let top = window.pageYOffset;

                if (top) {
                    if (top > last_scroll_top) {
                        // Scrolling down
                        header.classList.add(stickyheader);
                        menu.classList.add(stickymenu);
                        menu.classList.remove('show-text');
                    } else {
                        if (top < 80) {
                            // Scrolling up
                            header.classList.remove(stickyheader);
                            menu.classList.remove(stickymenu);

                            if (!isMobile) { //TODO: FOR SOME REASON THIS MESSES UP THE MOBILE NAV AFTER RESIZE. FIX IT!
                                setTimeout(e => {
                                    menu.classList.add('show-text');
                                }, 200);
                            }
                        }
                    }

                }

                last_scroll_top = top <= 0 ? 0 : top;
            });
        }
    }
}