let $list = document.createElement("div");

$list.classList.add("notification-list");

document.body.appendChild($list);

function position() {
    $list.style.bottom = Math.max(85, 50 - scrollY) + "px";
}
position();
addEventListener("load", position);
addEventListener("scroll", position);

function html(str: string): string {
    return str
        .replace(/&/g, '&amp;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;');
}

export function notify(type: "success"|"error"|"notice", title: string, message?: string) {
    let $notification = document.createElement("div");

    $notification.classList.add("notification", "notification--slide-in");

    if(type === "error") {
        // Used for situations where interrupting work flow is necessary.
        // Don't use alert on auto-close notifications.
        $notification.setAttribute("role", "alert");
    } else {
        $notification.setAttribute("role", "status");
    }

    $notification.innerHTML = `
    <div class="notification__box notification--${ type }">
        <button type="button" class="close notification__close" title="Dismiss" aria-label="Dismiss">X</button>
        <div class="notification__title">${ html(title) }</div>
        <div class="notification__text">${ message ? "<p class='notification__paragraph'>" + html(message) + "</p>" : "" }</div>
    </div>
    `;

    $list.insertBefore($notification, $list.firstChild);

    setTimeout(() => $notification.classList.remove("notification--slide-in"), 300);

    let close = () => {
        $notification.classList.add("notification--slide-out");

        setTimeout(() => {
            if ($list.contains($notification)) {
                $list.removeChild($notification)
            }
        }, 300);
    };

    if (type !== "error") {
        setTimeout(close, 3500); // auto-close non-error messages after a timeout
    }

    $notification.querySelector(".close")!.addEventListener("click", close);
}
